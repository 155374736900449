.auth-error {
  text-align: center;
  margin-bottom: 30px;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* Loader */
.root_loader {
  text-align: center;
  margin-top: 200px;
}
